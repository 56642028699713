import { defineStore, storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { usePriceService } from '@/services/price-service';
import { useProductStore } from '@/stores/product-store.js';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';

export const usePriceStore = defineStore('price', () => {
  const priceService = usePriceService();
  const loggedPrices = ref(null);
  const checkedProductIdsForLoggedPrice = ref(null);
  const productStore = useProductStore();
  const { currentDomProductIds: productIds } = storeToRefs(productStore);
  const shoppingCartStore = useShoppingCartStore();
  const externalPrices = ref([]);

  // get initial products on page load 
  const initPriceStore = async () => {
    if (!productIds.value || productIds.value.length < 1) {
      return;
    }
    await getLoggedPrices(productIds.value);
  }

  watch(productIds, (newValue, oldValue) => {
    // getLoggedPrices(productStore.currentDomProductIds);
    fetchExternalPrice(productStore.currentDomProductIds);
  });

  // keep track of all fetched ids to avoid fetching more than once. 
  const checkProductIds = async (productIds) => {
    productIds.forEach((id) => {
      if (!checkedProductIdsForLoggedPrice.value) {
        checkedProductIdsForLoggedPrice.value = productIds;
      } else if (!checkedProductIdsForLoggedPrice.value.includes(id)) {
        checkedProductIdsForLoggedPrice.value.push(id);
      }
    });
  }

  const fetchExternalPrice = async (productData) => {
    const body = new URLSearchParams();
  
    productData.forEach((product) => {
      let in_cart = false;
  
      shoppingCartStore.products.forEach((cartProduct) => {
        if (cartProduct.id === product) {
          body.append(`products[${product}]`, cartProduct.quantity);
          in_cart = true;
        }
      });
  
      if (!in_cart) {
        body.append(`products[${product}]`, 1); 
      }
    });
  
    try {
      const res = await priceService.fetchExternalPrice(body);
      const data = await res.json();
      externalPrices.value = [...data.products, ...externalPrices.value];
    } catch (e) {
      throw new Error(e.message || "Failed to fetch external price");
    }
  };

  const findExternalPrice = (productModel) => {
    if (!externalPrices.value) {
      return;
    }
  
    const externalPrice = externalPrices.value.find((item) => {
      return item.product_model === productModel;
    });
  
    if (!externalPrice) {
      return;
    }
  
    const price = externalPrice.prices?.[0]?.price;
    return price;
  };

  const getLoggedPrices = async (productIds) => {
    try {
      let queryIds = productIds;

      if (checkedProductIdsForLoggedPrice.value) {
        queryIds = productIds.filter((id) => {
          return !checkedProductIdsForLoggedPrice.value.includes(id);
        });
      }

      if (queryIds.length < 1) {
        return;
      }
  
      const priceLogQueryParams = await createPriceLogQueryParams(queryIds);
      const res = await priceService.getLoggedPrices(priceLogQueryParams);
      const data = await res.json();

      if (data) {
        if (loggedPrices.value) {
          const filteredProducts = Object.values(data).filter(({ product_id: id1 }) => {
            return !loggedPrices.value.some(({ product_id: id2 }) => id2 === id1);
          });

          loggedPrices.value = [...loggedPrices.value, ...filteredProducts];
        } else {
          loggedPrices.value = Object.values(data);
        }
      }

      checkProductIds(productIds);
    } catch(e) {
      throw new Error(e);
      // TODO handleApiError
    }
  }

  const createPriceLogQueryParams = async (productIds) => {
    let queryString = '';
    productIds.forEach(id => queryString += `id[]=${id}&`);
    return queryString;
  }

  const findLoggedPrice = (productId) => {
    if (!loggedPrices.value) {
      return;
    }
    return loggedPrices.value.find((item) => item.product_id == productId );
  }

  initPriceStore();

  return {
    loggedPrices,
    getLoggedPrices,
    findLoggedPrice,
    findLoggedPrice,
    fetchExternalPrice,
    findExternalPrice,
    externalPrices
  }
});
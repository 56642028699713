<template>
	<div class='account account-history-info margin-bottom-30'>
		<div class='row column text-center medium-text-left'>
			<button
				class='svg-icon step-back'
				@click.prevent='goBack'
				:aria-label='$translate("BACK_TO_ORDER_HISTORY")' role="button"
			>
				<LongArrowLeftIcon />
				{{ $translate('BACK_TO_ORDER_HISTORY') }} 
			</button>
		</div>
		<div class='row'>
			<div class='small-12 columns'>
				<h1 class='margin-bottom-30'>{{ orderData.number }}</h1>
				<div v-if='canReorder' class='account-page-text'>
					<h2>{{ $translate('REORDER_HEADLINE') }}</h2>
					<p>{{ $translate('REORDER_TEXT') }}</p>
					<button class='button primary' form='reorder'>{{ $translate('REORDER_BUTTON') }}</button>
				</div>
			</div>
		</div>
		
		<span class="trackno">
			{{ getTrackNo(orderData.description) }}
		</span>

		<div class='row columns'>
			<table class='unstriped'>
        <caption class="account-page-text">
					{{ getFilteredDesc(orderData.description) }}
				</caption>
				<thead>
					<tr>
						<th scope='col' class='text-left'>{{ $translate('LABEL_PRODUCTS') }}</th>
						<th scope='col' class='quantity text-center'>{{ $translate('TABLE_HEADING_QUANTITY') }}</th>
						<th scope='col' class='total text-right'>{{ $translate('ACCOUNT_ORDER_PRODUCT_TOTAL') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for='(product, index) in orderData.lines' :key='product.itemCode'>
						<td class='info'>
							<a v-if="product.vendreProductId" :href='`product.php?products_id=${product.vendreProductId}`' class='name'>{{ product.description }}</a>
							<span v-else class='name'>{{ product.description }}</span>
							<span>
								{{ product.itemCode }}
							</span>
						</td>
						<td class='text-center quantity'>
							<span v-if='!canReorder || !product.vendreProductId' class='quantity-num'>{{ product.quantity }}</span>
							<a v-if='canReorder && product.vendreProductId' :href='`product.php?products_id=${product.vendreProductId}`' class='dark text-underline'>{{ $translate('REORDER_BUTTON') }}</a>
							<div v-if='canReorder && product.vendreProductId' class='quantity-num'>
								<input type='hidden' form='reorder' :value='product.vendreProductId' :name='`products[${index}][products_id]`' />
								<input type='number' form='reorder' :value='product.quantity' :name='`products[${index}][quantity]`' />
							</div>
						</td>
						<td class='total text-right'>{{ displayPrice(product.price) }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class='row bottom-row a4s-order-info-footer'>
			<div class='a4s-order-info-address-parent'>
				<div class='columns a4s-order-info-address'>
					<h5>{{ $translate('HEADING_DELIVERY_ADDRESS') }}</h5>
					<span>{{ orderData.customerName }}</span>
					<span>{{ orderData.deliveryAddressLine1 }}</span>
					<span v-if='orderData.deliveryAddressLine2?.length'>{{ orderData.deliveryAddressLine2 }}</span>
					<span>{{ orderData.deliveryPostCode }} {{ orderData.deliveryCity }}</span>
					<span>{{ orderData.deliveryCountry }}</span>
				</div>
			</div>
			<div class='columns a4s-total'>
				<div v-if='props.showVat'>
					<div class='row ot_total'>

						<div class='columns small-8 text-right'>{{ $translate('ACCOUNT_ORDER_PRODUCT_TOTAL') }}</div>
						<div class='columns small-4 text-right'>{{ displayPrice(orderData.totalInclVat || orderData.total) }}</div>
					</div>
				</div>
				<div v-else>
          <div class='row ot_total'>
            <div class='columns small-8 text-right'>{{ $translate('ACCOUNT_ORDER_PRODUCT_TOTAL') }}</div>
						<div class='columns small-4 text-right'>{{ displayPrice(orderData.totalExclVat || orderData.total) }}</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="canReorder">
			<form action='' method='get' id='reorder'>
				<input type='hidden' name='action' value='buy_now' tabindex='-1'>
				<input type='hidden' name='target' value='checkout.php' tabindex='-1'>
			</form>
		</div>
	</div>
</template>

<script setup>
import { inject } from 'vue';
import LongArrowLeftIcon from '@/assets/icons/long-arrow-left.svg?component';
const emit = defineEmits(['closeSpecificOrder']);

const { $translate } = inject('i18n');

const props = defineProps({
  canReorder: {
    type: Boolean,
    required: true
  },
  showVat: {
    type: Boolean,
    default: true
  },
  orderData: {
    type: Object,
    required: true
  }
});

function goBack() {
	emit('closeSpecificOrder');
};

const getTrackNo = (description) => {
	const regex = /(?<=Trackno: ).*/; // Match everything after 'Trackno:'
  const match = description.match(regex);
  return match ? `${$translate('C_TRACKING_NUMBER')}: ${match[0]}` : '';
};

const getFilteredDesc = (description) => {
	const regex = /^(.*?)Trackno:/i; // Match everything before 'Trackno:'
	const match = description.match(regex);
	return match ? match[1].trim() : description;
};

const displayPrice = (productPrice) => {
	let currencySymbol = props.orderData.currencySymbol || props.orderData.currencyCode;
	if(!currencySymbol){
		currencySymbol = '';
	}
  return `${productPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${currencySymbol}`;
}

</script>

<template>
  <div class="cart-row">
    <button 
      @click="shoppingCartStore.removeProduct(product.id)" 
      class="delete" 
      aria-label="Remove product"
    >
      <TrashCanIcon />
    </button>

    <!-- Product image -->
    <div class="image">
      <a :href="product.href" aria-label="product name">
        <Image
          :source="product.image?.image"
          :estimated-viewport-widths="{ small: 40, medium: 20, large: 20 }"
        />
      </a>
    </div>
    <div class="cart-info">
      <div class="product-info">
        
        <!-- Product title -->
        <div v-if="product.manufacturer_name" class="product-title">
          <a :href="product.href">{{ product.manufacturer_name }}</a>
        </div>
        <p>{{ product.name }}</p>

        <ProductStockStatus 
          :allow-checkout="product.stock_allow_checkout"
          :stock="product.stock_quantity"
        />
        
        <!-- Attributes-->
        <div class="attributes">
          <span v-for="(attribute, idx) in product.attributes" :key="idx">
            <b>{{ attribute.name }}</b> {{ attribute.value }}
          </span>
        </div>
      </div>

      <!-- Quantity -->
      <div class="quantity">
        <button 
          @click="quantityInput.decreaseQuantity()"
          type="button" 
          aria-label="Decrease quantity"
        >
          <MinusIcon />
        </button>
        
        <ProductQuantityInput 
          v-model="quantity"
          aria-label="Product quantity input"
          ref="quantityInput"
          :hide-spin-buttons="true"
          @focus="cachedQuantity = quantity"
          @blur="handleInputBlur"
        />
        
        <button
          @click="quantityInput.increaseQuantity()"
          type="button"
          aria-label="Increase quantity"
        >
          <PlusIcon />
        </button>
      </div>

      <!-- Price -->
      <div class="price-wrapper">
        <div
          v-if="hasDiscountPrice" 
          :title="$translate('C_LOWEST_PRICE_DURING_PREVIOUS_30_DAYS_TOOLTIP')"
          class="price"
          :class="{ old: hasDiscountPrice }"
        >
          {{ product.total_price }}
          <InfoIcon />
        </div>

        <div 
          class="price"
          :class="{ new: hasDiscountPrice }"
        >
          {{ externalPrice }}
        </div>
      </div>
    </div>    
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { debounce } from '@/composables/debounce';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
import InfoIcon from '@/assets/icons/information-blue-rounded.svg?component';
import TrashCanIcon from '@/components/icons/TrashCanIcon.vue';
import Image from '@/components/Image.vue';
import ProductQuantityInput from '@/components/form/ProductQuantityInput.vue';
import { usePriceStore } from '@/stores/price-store.js';

const shoppingCartStore = useShoppingCartStore();
const props = defineProps(['product']);
const quantityInput = ref();
const cachedQuantity = ref();
const priceStore = usePriceStore();

const product = computed(() => {
  return props.product;
});

const externalPrice = computed(() => {
  return window.VendreCurrencyFormatter(priceStore.findExternalPrice(product.value.model) * product.value.quantity);
});

const quantity = ref(props.product.quantity);

watch(quantity, () => {
  updateCart();
});

const hasDiscountPrice = computed(() => {
  if (product.value.total_final_price_raw < product.value.total_price_raw) {
    return true;
  }
  return false;
});

const handleInputBlur = () => {
  if (quantity.value !== cachedQuantity.value) {
    updateCart();
  }
}

const updateCart = () => {
  debounce(() => {
    const productData = {
      id: product.value.id,
      quantity: parseInt(quantity.value)
    };

    shoppingCartStore.setProducts(productData);
  }, 500);
}
</script>
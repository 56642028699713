<template>
  <div class="product-listing-price-wrapper">
    <div class="prices">
      <span 
        v-if="discountPrice"
        class="price"
        :class="{ 'price-new': displayRedPrice }"
      >
        {{ discountPrice }}
      </span>

      <span 
        v-if="regularPrice && !discountPrice" 
        class="price"
      >
        {{ regularPrice }}
      </span>

      <span 
        v-if="regularPrice && discountPrice && displayRedPrice" 
        :title="$translate('C_LOWEST_PRICE_DURING_PREVIOUS_30_DAYS_TOOLTIP')"
        :class="{ 'price-old': discountPrice }"
      >
        {{ regularPrice }}
        <InfoIcon />
      </span>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import InfoIcon from '@/assets/icons/information-blue-rounded.svg?component';
import { useProductPrice } from '@/composables/product-price.js';

export default {
  props: {
    showVat: {
      type: Boolean,
      required: true
    },
    updateOnChange: {
      type: Boolean,
      required: false
    },
    productData: {
      type: Object,
      required: true
    },
    handlePriceLogUpdate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    InfoIcon
  },
  setup(props) {
    const productData = ref(props.productData);

    // Converts to the same type of values used on the product page.
    productData.value.id = parseInt(productData.value.id);
    productData.value.lowest_number = parseInt(productData.value.lowest_number);
    productData.value.price_discount_percentage = parseInt(productData.value.price_discount_percentage?.replace('%', ''));

    const {
      intialPriceLoaded,
      price,
      handleUpdatedProductConfiguratorPrice,
      getPrice,
      discountPrice,
      regularPrice,
      discountPercentage,
      vatText,
      displayRedPrice
    } = useProductPrice(props.productData, props);

    onMounted(async () => {
      if (props.updateOnChange && props.productData.id) {
        await getPrice(props.productData.id);
        const productForm = document.querySelector('form[name=add_product]');

        if (productForm) {
          productForm.addEventListener('input', getPrice);
        }
      }

      document.addEventListener(
        'vendreProductPageProductConfiguratorPriceUpdated',
        handleUpdatedProductConfiguratorPrice
      );

      intialPriceLoaded.value = true;
    });

    onUnmounted(() => {
      document.removeEventListener(
        'vendreProductPageProductConfiguratorPriceUpdated',
        handleUpdatedProductConfiguratorPrice
      );
      const productForm = document.querySelector('form[name=add_product]');
      if (productForm) {
        productForm.removeEventListener('input', getPrice);
      }
    });

    return {
      InfoIcon,
      vatText,
      regularPrice,
      discountPercentage,
      discountPrice,
      displayRedPrice
    }
  }
}
</script>
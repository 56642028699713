export const usePriceService = () => {

  const getLoggedPrices = (priceLogQueryParams) => {
    return fetch(`/surface/1/products/price_log_prices?${priceLogQueryParams}`);
  }

  const fetchExternalPrice = async (body) => {
    return fetch("/ajax.php?mode=external_pricelists", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body,
    });
  };

  return {
    getLoggedPrices,
    fetchExternalPrice
  }
}
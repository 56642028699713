<template>
  <div v-if="showSpecificOrder && currentOrderId && currentOrderData">
    <div data-vue-wrapper>
      <account-order-history-info 
        :can-reorder="canReorder" 
        :show-vat="showVat" 
        :order-data="currentOrderData" 
        @closeSpecificOrder="closeSpecificOrder()"
      />
    </div>
  </div>
  <div v-else>
    <div v-if="orderHistoryList.length" class="account-order-history">
      <div v-if="!isOrderListView" class="row column">
        <h2>{{ $translate('ALL_ORDERS_TABLE_HEADING') }}</h2>
      </div>
      <table class="unstriped">
        <thead>
        <tr>
          <th>{{ $translate("ACCOUNT_ORDER_ORDERID") }}</th>
          <th>{{ $translate("ACCOUNT_ORDER_DATE") }}</th>
          <th>{{ $translate('C_TRACKING_NUMBER') }}</th>
          <th class="hide-for-small-only text-right">{{ $translate("ACCOUNT_ORDER_ORDERTOTAL") }}</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="order in relevantOrders" :key="order.number || order.detailsCallParameter">
          <td>{{ order.number || order.detailsCallParameter }}</td> 
          <td class="hide-for-small-only">{{ formatDate(order.documentDate || order.created, 'yyyy-MM-dd') }}</td>
          <td class="show-for-small-only">{{ formatDate(order.documentDate  || order.created, 'dd/MM') }}</td>
          <td>{{ getTrackNo(order) }}</td>
          <td class="hide-for-small-only text-right" 
            v-html="showVat ? displayPrice(order.totalInclVat || order.total, order.currencyCode || order.currencySymbol) 
                    : displayPrice(order.totalExclVat || order.total, order.currencyCode || order.currencySymbol)">
          </td>
          <td class="text-right">
            <button @click="() => { currentOrderId = order.number || order.detailsCallParameter }" class="dark text-underline">
              {{ $translate("HEADER_PRODUCTS_SHOW") }}
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <hr>
      <a v-if="!isOrderListView" role="button" :href="`/account_history.php`" class="float-right dark underline view-orders">{{ $translate('OVERVIEW_SHOW_ALL_ORDERS') }}</a>
      <button v-if="isOrderListView && hasMore" :disabled="loading" @click="showMoreOrders" class="button primary">
        {{ $translate('VIEW_MORE_PRODUCTS') }}
      </button>
    </div>
    <div v-else-if="!loading">
      <p>{{ $translate('TEXT_NO_PURCHASES') }}</p>
    </div>
  </div>
</template>

<script setup>
import { inject, onBeforeMount, onMounted, ref, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useA4sOrdersStore } from '@/stores/a4s-orders-store.js';

const a4sStore = useA4sOrdersStore();
const { orderHistoryList, hasMore, loading } = storeToRefs(a4sStore);

const props = defineProps({
  showVat: {
    type: Boolean,
    default: true
  },
  rowAmount: {
    type: Number,
    default: 3
  },
  offset: {
    type: Number,
    default: 0
  },
  canReorder: {
    type: Boolean,
    default: false
  },
  orderLimit: {
    type: Number,
    default: 0
  }
});

const { $translate } = inject('i18n');
const currentOffset = ref(props.offset);
const showMoreOrders = () => {
  currentOffset.value += props.rowAmount;
  a4sStore.fetchOrderHistory({ rows: props.rowAmount, offset: currentOffset.value });
};

const relevantOrders = computed(() => {
  if(props.orderLimit) {
    return orderHistoryList.value.slice(0, props.orderLimit);
  } else {
    return orderHistoryList.value;
  }
});

const regex = /(?<=Trackno: ).*/;
const getTrackNo = (order) => {
  const match = order.description.match(regex);
  return match ? match[0] : '';
};

const showSpecificOrder = ref(false);
const currentOrderId = ref(0);
const currentOrderData = ref(null);

function formatDate(timestamp, formatStr) {
  const date = new Date(timestamp);
  const options = {};

  switch (formatStr) {
    case 'yyyy-MM-dd':
      options.year = 'numeric';
      options.month = '2-digit';
      options.day = '2-digit';
      break;
    case 'dd/MM':
      options.day = '2-digit';
      options.month = '2-digit';
      break;
    default:
      return date.toLocaleDateString();
  }

  return date.toLocaleDateString(undefined, options).replace(/\//g, '-');
}

function closeSpecificOrder() {
  showSpecificOrder.value = false;
  currentOrderId.value = null;
}

const isOrderListView = ref(false);
onBeforeMount(() => {
  isOrderListView.value = window.location.href.includes("/account_history.php");
});


onMounted(async () => {
  a4sStore.fetchRealtimeOrderHistory();
});

watch(currentOrderId, async () => {
  if(currentOrderId) {
    currentOrderData.value = await a4sStore.fetchRealtimeOrderData(currentOrderId.value);
    showSpecificOrder.value = true;
  } else {
    a4sStore.clearCurrentOrderData();
    showSpecificOrder.value = false;
    currentOrderData.value = null;
  }
});

const displayPrice = (productPrice, currencyCode) => {
  return `${productPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${currencyCode}`;
};
</script>

<template>
  
  <!-- Toggle popup icon -->
  <div 
    class="products-lists-toggle-wrapper"
    @click.prevent="togglePopup"
  >  
    <HeartIcon 
      v-show="!customerProductListsStore.isProductInList(productId)" 
      class="icon" 
    />
    
    <SolidHeartIcon 
      v-show="customerProductListsStore.isProductInList(productId)" 
      class="selected-icon" 
    /> 
  </div>

  <!-- POPUP -->
  <Teleport to="body">
    <div class="product-list-popup-wrapper" 
      v-show="showPopup" 
      @click.prevent
    >  
      <div @click.self="togglePopup" class="overlay">
        <div class="product-list-popup">
          <!-- Close -->
          <div class="close-favourite-popup">
            <span 
              @click="togglePopup" 
              aria-hidden="true"
              class="popup-close-icon"
            >
              <CloseIcon />
            </span>
          </div>
  
          <h6>{{ $translate('P_FAVORITES_ADD_TO_WISHLIST') }}</h6>
          <ul class="list">
            <!-- Favorites list-->
            <li @click="toggleProductInFavoritesList">
              <input v-model="isFavorite" type="checkbox">
              {{ $translate('TEXT_MY_FAVORITES') }}
            </li>
            
            <!-- Custom lists -->
            <li
              v-for="(list, idx) in customerProductListsStore.customLists"
              :key="idx"
              @click="toggleProductInCustomList(productId, list)"
            >
              <input 
                :value="productId"
                v-model="customerProductListsStore.customListsReducedToProductIds[idx].products"
                type="checkbox"
              >
                {{ list.name }}
            </li>
          </ul>
  
          <div class="row collapse">      
            <div class="small-12 columns create-new-list">
              <input
                v-model="newListName" 
                class="add-list-name"
                type="text"
              />
            </div>
              <button 
                class="create-list button primary" 
                @click="createNewList"
                :disabled="!newListName"
                :class="{ inactive: !newListName }"
              >
                {{ $translate('HEADING_TITLE_CREATE_CUSTOMERS_PRODUCTS_LIST') }}
              </button>      
          </div>
  
        </div>
      </div>
    </div>
  </Teleport>

</template>

<script>
import { ref, computed } from 'vue';
import { useCustomerProductLists } from '@/stores/customer-products-list.js';
import SolidHeartIcon from '@/assets/icons/package5/heart-solid.svg?component';
import HeartIcon from '@/assets/icons/heart.svg';
import CloseIcon from '@/assets/icons/close.svg?component';

export default {
  props: {
    productId: {
      required: true,
      type: Number
    }
  },
  components: {
    SolidHeartIcon,
    HeartIcon,
    CloseIcon
  },
  setup(props) {
    const productId = props.productId;
    const showPopup = ref(false);
    const newListName = ref('');

    const customerProductListsStore = useCustomerProductLists();
    
    const isFavorite = computed(() => {
      return customerProductListsStore.isFavorite(productId);
    });

    function createNewList() {
      customerProductListsStore.createNewList(newListName.value)
        .then(() => {
          newListName.value = '';
        })
        .catch((err) => {
          console.log('Error creating new list');
        });
    }
    
    function togglePopup() {
      newListName.value = '';
      
      // Multiple lists enabled - Open modal
      if (window.vendre_config.account.favorites_custom_lists) {
        showPopup.value = !showPopup.value;
      }
      // Multiple lists disabled - Toggle product in favorites list
      if (!window.vendre_config.account.favorites_custom_lists) {
        customerProductListsStore.toggleProductInFavoritesList(productId);
      }
    }
    
    function toggleProductInFavoritesList() {
      customerProductListsStore.toggleProductInFavoritesList(productId)
        .catch((err) => {
          console.error('Could not add product to list');
        });
    }

    function toggleProductInCustomList(productId, list) {
      customerProductListsStore.toggleProductInCustomList(productId, list)
        .catch((err) => {
          console.log('Could not toggle product in list');
        });
    }

    return {
      customerProductListsStore,
      productId,
      showPopup,
      togglePopup,
      toggleProductInFavoritesList,
      isFavorite,
      createNewList,
      newListName,
      toggleProductInCustomList,
      
      // Icons
      HeartIcon,
      SolidHeartIcon,
      CloseIcon
    }
  }
}
</script>

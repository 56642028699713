import { defineStore } from 'pinia';
import axios from 'axios';
const baseUrl = '/surface/1/appforsales/history';
const realtimeBaseUrl = '/surface/1/appforsales/realtime';

export const useA4sOrdersStore = defineStore('a4sOrders', {
  state: () => ({
    invoiceHistory: [],
    invoiceData: null,
    orderHistory: [],
    orderData: null,
    loading: false,
    hasMore: true
  }),
  getters: {
    invoiceHistoryList: (state) => state.invoiceHistory,
    currentInvoiceData: (state) => state.invoiceData,
    orderHistoryList: (state) => state.orderHistory,
    currentOrderData: (state) => state.orderData,
  },
  actions: {
    // data: { rows: number, offset: number }
    async fetchInvoiceHistory(data) {
      this.loading = true;
      try {
        const response = await axios.post(`${baseUrl}/invoices`, data);
        this.invoiceHistory = [...this.invoiceHistory, ...response.data];
        if(response?.data?.length < data.rows) {
          this.hasMore = false;
        }
      } catch (error) {
        console.error('Error fetching invoice history:', error);
      } finally {
        this.loading = false;
      }
    },
    // invoice_number
    async fetchInvoiceData(invoiceId) {
      try {
        const response = await axios.post(`${baseUrl}/invoice?invoice_number=${invoiceId}`);
        this.invoiceData = response.data;
      } catch (error) {
        console.error('Error fetching invoice data:', error);
      }
    },
    clearCurrentInvoiceData() {
      this.invoiceData = null;
    },
    // data: { rows: number, offset: number }
    async fetchOrderHistory(data) {
      this.loading = true;
      try {
        const response = await axios.post(`${baseUrl}/orders`, {rows: data.rows, offset: data.offset});
        this.orderHistory = [...this.orderHistory, ...response.data];
        if(response?.data?.length < data.rows) {
          this.hasMore = false;
        }
        return this.orderHistory;
      } catch (error) {
        console.error('Error fetching order history:', error);
        return null;
      } finally {
        this.loading = false;
      }
    },
    // order_number
    async fetchOrderData(orderId) {
      try {
        const res = await axios.post(`${baseUrl}/order`, { order_number: orderId });
        return res?.data;
      } catch (error) {
        console.error('Error fetching order data:', error);
        return null;
      }
    },
    async fetchRealtimeOrderHistory() {
      try {
        this.loading = true;
        window.Loader.addLoader();
        const response = await axios.post(`${realtimeBaseUrl}/orders`);
        this.orderHistory = response.data;
        this.hasMore = false;
        return this.orderHistory;
      } catch (error) {
        console.error('Error fetching order history:', error);
        return null;
      } finally {
        this.loading = false;
        window.Loader.removeLoader();
      }
    },
    // order_number 
    async fetchRealtimeOrderData(orderId) {
      try {
        window.Loader.addLoader();
        const res = await axios.post(`${realtimeBaseUrl}/order`, { order_number: orderId });
        return res?.data;
      } catch (error) {
        console.error('Error fetching order data:', error);
        return null;
      } finally {
        window.Loader.removeLoader();
      }
    },
    clearCurrentOrderData() {
      this.orderData = null;
    }
  }
});